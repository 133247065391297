/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-tick:before { content: "\f100"; }
.flaticon-big-data:before { content: "\f101"; }
.flaticon-science:before { content: "\f102"; }
.flaticon-document:before { content: "\f103"; }
.flaticon-data-analytics:before { content: "\f104"; }
.flaticon-chart:before { content: "\f105"; }
.flaticon-data:before { content: "\f106"; }
.flaticon-add:before { content: "\f107"; }
.flaticon-tick-1:before { content: "\f108"; }
.flaticon-quote:before { content: "\f109"; }
.flaticon-call-answer:before { content: "\f10a"; }
.flaticon-envelope:before { content: "\f10b"; }
.flaticon-maps-and-flags:before { content: "\f10c"; }
.flaticon-data-management:before { content: "\f10d"; }
.flaticon-right-chevron:before { content: "\f10e"; }
.flaticon-left-chevron:before { content: "\f10f"; }
.flaticon-right:before { content: "\f110"; }